import { option, partConfiguration } from './setting-mzy';
const configuration: any = partConfiguration;
/**
 * @description 根据环境配置api
 */
const getOption = (): Promise<any> => {
    return new Promise((resolve, reject) => {
        let dsObj = option();
        resolve(dsObj);
    });
};
export { getOption, configuration };