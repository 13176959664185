import { RouteConfig } from 'vue-router';
const HisList = () => import('./views/his-list.vue');
const HisMenzhen = () => import('./views/his-menzhen.vue');
const HisZhuyuan = () => import('./views/his-zhuyuan.vue');
const HisJianyan = () => import('./views/his-jianyan.vue');
const HisJianCha = () => import('./views/his-jiancha.vue');
const HisChufang = () => import('./views/his-chufang.vue');
const routers: RouteConfig[] = [
    {
        path: 'his-list',
        name: 'his-list',
        component: HisList
    },
    {
        path: 'his-menzhen',
        name: 'his-menzhen',
        component: HisMenzhen
    },
    {
        path: 'his-zhuyuan',
        name: 'his-zhuyuan',
        component: HisZhuyuan
    },
    {
        path: 'his-jianyan',
        name: 'his-jianyan',
        component: HisJianyan
    },
    {
        path: 'his-jiancha',
        name: 'his-jiancha',
        component: HisJianCha
    },
    {
        path: 'his-chufang',
        name: 'his-chufang',
        component: HisChufang
    },
    {
        path: '*',
        redirect: 'his-list'
    },
];
export default routers;
