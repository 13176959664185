import Axios, { AxiosResponse } from 'axios';
import router from '../router';
import { AxiosRequestConfig } from 'axios';
import { getOption } from '@/setting';
import store from './../store';

const blackApiList: string[] = [];
getOption().then((data: any) => {
    window.OPTION = data;
});
console.log('ffffffffff');
console.log(window.API_ROOT)
const baseConfig: AxiosRequestConfig = {
    baseURL: window.API_ROOT,
};
const axios = Axios.create(baseConfig);
const ifAddAuthorization = (config: any): AxiosRequestConfig => {
    // const token = localStorage.getItem('token');
    const token = store.state.token;
    if (token && typeof (token) === 'string' && token !== 'undefined') {
        const url = config.url;
        let isBlack = false;
        for (const api of blackApiList) {
            if (url && url.includes(api)) {
                isBlack = true;
            }
        }
        if (isBlack) {
            delete config.headers.common.Authorization;
        } else {
            config.headers.common.Authorization = '' + 'Bearer ' + token;
        }
    } else {
        console.log(token);
    }
    return config;
};
axios.interceptors.request.use((config: AxiosRequestConfig) => {
    config = ifAddAuthorization(config);
    return config;
});

axios.interceptors.response.use((value: AxiosResponse) => {
    return value;
}, (error: any) => {
    console.log(error);
});

export default axios;

const resHandle = (VueComponent: any, data: any, loading?: any) => {
    return new Promise((resolve, reject) => {
        if (data.error_code !== 0) {
            if (data.error_code === 401) {
                VueComponent.$message({
                    type: "error",
                    message: data.msg
                });
                localStorage.removeItem("token");
                router.push("/login");
                reject(data.msg);
            } else if (data.error_code === 504) {
                VueComponent.$message({
                    type: "error",
                    message: data.msg
                });
                localStorage.removeItem("token");
                router.push("/login");
                reject(data.msg);
            } else if (data.error_code === 500) {
                VueComponent.$message({
                    type: "error",
                    message: "接口报错"
                });
                reject(false);
            } else {
                VueComponent.$message({
                    type: "error",
                    message: data.msg
                });
                if (loading) {
                    loading.close()
                }
                reject(data.msg);
            }
        } else {
            if (loading) {
                loading.close()
            }
            resolve(data);
        }
    });
};

export { resHandle };
